

/**
 * Always hide an element when it has the `hidden` HTML attribute.
 */

.hidden {
    display: none !important;
}

html, body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
    text-decoration: none;
    cursor: pointer;
}

/**
 * AWS Amplify Cognito Funktionen an das restliche Template anpassen.
 */

.Anchor__a___1_Iz8 {
    color: #3f51b5;
}

.Button__button___vS7Mv,
.Button__button___vS7Mv:active {
    background: #3f51b5;
    color: white;
    outline: none;
    border-radius: 5px;
}

.Section__sectionFooterSecondaryContent___Nj41Q,
.Section__sectionFooterPrimaryContent___2r9ZX {
    text-decoration: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

*{
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}